import React from 'react';
class ProjectShowcase extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hidden: true };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    this.removeScrollListener();
  }

  onScroll = () => {
    if (this.checkVisible(this.element)) {
      this.setState({ hidden: false });
      this.removeScrollListener();
    }
  };

  removeScrollListener = () => {
    window.removeEventListener('scroll', this.onScroll);
  };

  checkVisible = element => {
    let rect = element.getBoundingClientRect();
    let viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    let offset = rect.height * 0.5;
    return rect.top < viewHeight - offset;
  };

  render() {
    const { component } = this.props;
    console.log(component);

    return (
      <section
        ref={r => (this.element = r)}
        className={`project-partial project-partial-${component.type} animate-partial ${this.state.hidden && 'hidden'}`}
        style={{ backgroundColor: component.background_color }}
      >
        {component.background_image && (
          <img className="partial-background" src={component.background_image.childImageSharp.fluid.src} alt="background" />
        )}
        <div className="container">
          {component.type !== 'device-front-generic15' ? (
            <div className={`row ${component.direction === 'right' ? 'flex-row-reverse' : ''}`}>
              <div className="col-md-6">
                <p
                  className={`project-partial-column-text text-center ${
                    component.direction !== 'right' ? 'text-md-right' : 'text-md-left'
                  } `}
                  style={{ color: component.foreground_color }}
                >
                  {component.text}
                </p>
              </div>
              <div className="col-md-6">
                <div className="project-partial-column-image">
                  {component.type.includes('device-hand') && (
                    <div className={`device-hand ${component.type} animate-down`}>
                      {component.images && component.images[0].includes('.mp4') ? (
                        <video muted autoPlay loop>
                          <source src={component.images[0]} type="video/mp4" />
                        </video>
                      ) : component.images.length > 1 ? (
                        component.images.map((image, index) => (
                          <img
                            src={image}
                            className={`slideshow-${index + 1}-slide`}
                            key={index}
                            alt={`slide${index + 1}`}
                          />
                        ))
                      ) : (
                        <img src={component.images[0]} alt="component" />
                      )}
                    </div>
                  )}
                  {component.type === 'isometric' && (
                    <div className={`isometric-container direction-${component.direction}`}>
                      <img src={component.images[0]} className="isometric-image top animate-isometric" alt="top" />
                      <img
                        src={component.images[1]}
                        className="isometric-image middle animate-isometric"
                        alt="middle"
                      />
                      <img
                        src={component.images[2]}
                        className="isometric-image bottom animate-isometric"
                        alt="bottom"
                      />
                    </div>
                  )}
                  {component.type.includes('device-front') && (
                    <div className="device-front-container">
                      <div className="device-front-foreground">
                        <div
                            className={`device-front ${component.type} ${component.orientation} animate ${
                              component.direction === 'right' ? 'device-front-left' : ''
                            }`}
                          >
                            {component.images && component.images[0].includes('.mp4') ? (
                              <video muted autoPlay loop>
                                <source src={component.images[0]} type="video/mp4" />
                              </video>
                            ) : component.images.length > 1 ? (
                              component.images.map((image, index) => (
                                <img
                                  src={image}
                                  className={`slideshow-${index + 1}-slide`}
                                  key={index}
                                  alt={`slide${index + 1}`}
                                />
                              ))
                            ) : (
                              <img src={component.images[0]} alt="component" />
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12">
                <p
                  className="project-partial-column-text text-center mt-2"
                  style={{ color: component.foreground_color }}
                >
                  {component.text}
                </p>
              </div>

              <div className="col-md-12">
                <div className="project-partial-column-image" style={{ width: '100%' }}>
                  {component.type === 'device-front-generic15' && (
                    <div className="device-front-generic15-container">
                      <div className="device-front-generic15 animate-down">
                        {component.images && component.images[0].includes('.mp4') ? (
                          <video muted autoPlay loop>
                            <source src={component.images[0]} type="video/mp4" />
                          </video>
                        ) : (
                          <img src={component.images[0]} alt="component" />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }
}

export default ProjectShowcase;
