import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons'
import { Link } from 'gatsby'
import { scrollToForm } from '../contact/ContactForm'

const ProjectNavBar = () => (
  <nav className="navbar fixed-top navbar-light bg-lightest">
    <div className="container">
      <ul className="nav navbar-nav">
        <li className="nav-item">
          <Link to="/projects" className="nav-link">
            <FontAwesomeIcon icon={faArrowCircleLeft} /> back
          </Link>
        </li>
      </ul>
      <div className="float-right d-none d-md-block">
        <p className="text-right mb-0">
          <strong>MVP in 12 weeks and on budget?</strong> We've got you covered!
          <button
            data-form-category="startup"
            id="get-backer-hold-family-startup"
            className="btn btn-extra1 btn-sm ml-2"
            onClick={event => {
              event.preventDefault()
              scrollToForm(null, -50)
            }}
          >
            Hire us!
          </button>
        </p>
      </div>
    </div>
  </nav>
)

export default ProjectNavBar
