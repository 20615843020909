import React from 'react'
import {Category, scrollToForm} from '../contact/ContactForm'
import { StaticQuery, graphql } from 'gatsby';

const CtaMobile = ({ lightTheme }) => {
  
  return (
    <StaticQuery 
    query={graphql`
      query {
        imgLight: file(relativePath: { regex: "/cto_mobile_light_bg.png/" }) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
        img: file(relativePath: { regex: "/cto_mobile_bg.png/" }) {
          childImageSharp {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    `}
    render={({ imgLight, img }) => {
      const image = lightTheme ? imgLight.childImageSharp.fluid.src : img.childImageSharp.fluid.src   
      return (
        <section
          id="cta-mobile"
          className={`cto ${lightTheme ? 'bg-faded' : 'bg-dark'}`}
        >
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-4 col-lg-2">
                <img src={image} alt="Mobile Apps" className="cta-image" />
              </div>
              <div className="col-12 col-sm-8 col-lg-10">
                <div className="row">
                  <div className="col-md-12">
                    <h3>Get your mobile app in 3 easy steps!</h3>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-lg-3">
                    <div className="step">
                      <span className="counter">1</span>
                      <h4>Spec out</h4>
                      <p className="text-muted m-0">with our business analyst</p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="step">
                      <span className="counter">2</span>
                      <h4>Develop</h4>
                      <p className="text-muted m-0">
                        design, implement
                        <br />
                        and test, repeat!
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="step">
                      <span className="counter">3</span>
                      <h4>Publish</h4>
                      <p className="text-muted m-0">
                        get your app out
                        <br />
                        to the stores
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3">
                    <div className="step">
                      <h4>Interested?</h4>
                      <a
                        href="#contact-partial"
                        className="btn btn-extra1"
                        onClick={event => {
                          event.preventDefault()
                          scrollToForm(Category.MobileApps, -90, 500)
                        }}
                      >
                        Hire us now!
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}}
    />
  )
}

export default CtaMobile
