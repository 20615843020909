import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faClock, faGlobe, faMale} from '@fortawesome/pro-light-svg-icons'

const ProjectIntro = ({ project }) => {
  return (
    <section
      className='single-project'
      itemScope
      itemType='http://schema.org/CreativeWork'
    >
      <div className='single-project-intro'>
        <div
          className='background'
          style={{ backgroundColor: project.background_color }}
        >
          {project.background_image && (
            <div
              className='image'
              style={{
                backgroundImage: `url(${project.background_image.childImageSharp.fluid.src})`,
                backgroundColor: project.background_color,
              }}
            />
          )}
          {project.background_overlay && (
            <div
              className='color'
              style={{
                backgroundColor: project.background_color,
                opacity: 0.75,
              }}
            />
          )}
        </div>
        <div className='container'>
          <div className='row project-description'>
            <div className='col-xs-12 offset-xs-0 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3'>
              <h1
                className='seo-h3 text-center'
                itemProp='name'
                style={{ color: project.lead_color }}
              >
                {project.title}
              </h1>
              <h3
                className='seo-h1 text-center'
                style={{ color: project.lead_color }}
              >
                {project.headline}
              </h3>
              <h2
                className='lead seo-lead text-center'
                style={{ color: project.lead_color }}
                itemProp='description'
              >
                {project.description}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className='single-project-content'>
        <div className='container'>
          <div className='row fix-margin-project'>
            <div className='col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-xl-8 offset-xl-2'>
              <meta
                itemProp='thumbnailUrl'
                content={`../../assets/${project.image}`}
              />
              <img
                src={project.image.childImageSharp.fluid.src}
                alt={`${project.title} by zaven`}
                className='project-product-image'
              />
            </div>
          </div>
          <section className='project-facts'>
            <div className='row'>
              <div className='col-md-6 text-center'>
                <ul className='project-facts-list fa-ul'>
                  {project.team && (
                    <li>
                      <span className='fa-li'>
                        <FontAwesomeIcon icon={faMale} />
                      </span>
                      <span className='text-muted'>{project.team}</span>
                    </li>
                  )}
                  {project.time && (
                    <li>
                      <span className='fa-li'>
                        <FontAwesomeIcon icon={faClock} />
                      </span>
                      <span className='text-muted'>{project.time}</span>
                    </li>
                  )}
                  {project.country && (
                    <li>
                      <span className='fa-li'>
                        <FontAwesomeIcon icon={faGlobe} />
                      </span>
                      <span className='text-muted'>{project.country}</span>
                    </li>
                  )}
                </ul>
              </div>
              <div className='col-md-6 col-lg-5 col-xl-4'>
                <ul className='list-unstyled list-inline list-badges text-center text-sm-left'>
                  {project.tags &&
                    project.tags.map((tag, index) => (
                      <li key={index} >
                        <span className='badge badge-light'>
                          {tag}
                        </span>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </section>
          <section className='project-challenges' itemProp='about'>
            <div className='row '>
              <div className='col-md-6'>
                <h2 className='text-lowercase'>Challenge</h2>
                {project.challenge &&
                  project.challenge.map((line, index) => (
                    <p key={index} className='text-justify'>
                      {line}
                    </p>
                  ))}
              </div>
              <div className='col-md-6'>
                <h2 className='text-lowercase'>Results</h2>
                {project.result &&
                  project.result.map((line, index) => (
                    <p key={index} className='text-justify'>
                      {line}
                    </p>
                  ))}
                {project.result_links && (
                  <p className='result-links'>
                    <strong>You can see the result here: </strong>
                    {project.result_links.map(({ title, url }, index) => (
                      <a
                        key={index}
                        href={url}
                        target='_blank'
                        className='comma-after'
                        rel="noopener noreferrer"
                      >
                        {title}
                      </a>
                    ))}
                  </p>
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  )
}

export default ProjectIntro
