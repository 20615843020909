import React from 'react'
import ProjectNavBar from '../components/projects/ProjectNavBar'
import ProjectIntro from '../components/projects/ProjectIntro'
import Technologies from '../components/common/Technologies'
import ProjectShowcase from '../components/projects/ProjectShowcase'
import ProjectAboutClient from '../components/projects/ProjectAboutClient'
import SelectedProjectsContainer from '../components/home/SelectedProjectsContainer'
import CtaMobile from '../components/common/CtaMobile'
import ContactBottom from '../components/contact/ContactBottom'
import Divider from "../components/common/Divider"
import Helmet from "react-helmet"
import Layout from '../layouts';
import { graphql } from "gatsby";

const Project = ({ data }) => {

  const { project, client, selected_projects } = data
  const { legalName } = data.project_contact
  const { city, street, country, url } = data.project_contact.address.office
  const { email, phone, phoneClean, skype, skypeName } = data.project_contact.contact
  const workTime = data.project_contact.contact.workTimeUTC
  const mailingConfig = data.project_mailing_info
  console.log(client);
  const technologies = data.technologies.edges.filter(technology =>
    project.technologies.includes(technology.node.short)
  )

  return (
    <Layout>
      <Helmet
        title={`${project.title} | Projects | Zaven`}
        meta={[
          { name: 'description', content: project.description },
          { name: 'keywords', content: project.keywords },
        ]}
      />
      <ProjectNavBar />
      <ProjectIntro project={project} />
      <Technologies
        listOfTechnologies={technologies}
        className='technologies text-center my-5'
        style={{ padding: '1rem 0' }}
      />
      {project.components &&
        project.components.map((component, index) => (
          <ProjectShowcase component={component} key={index} />
        ))}
      <Divider fullWidth />
      {client && (
        <ProjectAboutClient
          client={client}
          style={{ padding: '2.5rem 1rem' }}
        />
      )}
      <SelectedProjectsContainer listOfProjectNodes={selected_projects.edges} />
      <CtaMobile />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTime}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
        mailingConfig={mailingConfig}
      />
    </Layout>
  )
}

export default Project

export const query = graphql`
  query ProjectQuery($slug: String!) {
    project: projectsYaml(slug: { eq: $slug }) {
      ...Project
    }
    technologies: allTechnologiesJson {
      ...Technologies
    }
    client: clientsJson(projects: { eq: $slug }) {
      ...Client
    }
    selected_projects: allProjectsYaml(
      filter: { slug: { ne: $slug } }
      sort: { fields: [order] }
      limit: 3
    ) {
      ...SelectedProjects
    }
    project_contact: dataJson {
      ...Contact
    }
  }
`
